<template>
  <v-container fluid>
    <v-row dense>
      <v-col>
        <h1>Quotation chase history for {{ monthName}} {{ year }}</h1>
      </v-col>
      <v-col class="text-right">
        <v-btn @click="exportToExcel()" small>Export to Excel</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-3">
        <v-date-picker
            v-model="date"
            type="month"
        ></v-date-picker>
      </v-col>
      <v-col class="col-9">
        <v-tabs v-model="tab">
          <v-tab
              v-for="(person, index) in salespeople"
              :key="`p_${index}`">
            {{ person.initials }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item
              v-for="(person, index) in salespeople"
              :key="`sp_${index}`">
            <v-simple-table>
              <thead>
              <tr>
                <th>Number</th>
                <th>Converted?</th>
                <th>Lost?</th>
                <th>Chased</th>
                <th>Date</th>
                <th>Created date</th>
                <th>Customer</th>
                <th>Address</th>
                <th>Value</th>
                <th>&nbsp;</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(quotation, index) in person.quotations" :key="`i_${index}`">
                <td>{{ quotation.clone_id }}-{{ quotation.clone_count }}-{{ quotation.quoted_by_initials }}</td>
                <td>
                  <span v-if="quotation.converted">Yes</span>
                  <span v-else>No</span>
                </td>
                <td>
                  <span v-if="quotation.lost">Yes</span>
                  <span v-else>No</span>
                </td>
                <td>{{ quotation.chaseCount }}</td>
                <td>{{ quotation.date | tinyDate }}</td>
                <td>{{ quotation.created_date | tinyDate }}</td>
                <td>{{ quotation.customer }}</td>
                <td>{{ quotation.address }}</td>
                <td>{{ quotation.value | priceInPounds | currency }}</td>
              </tr>
              </tbody>
            </v-simple-table>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'ChaseReport',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      salespeople: [],
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      monthName: '',
      year: '',
      tab: null,
      attrs: {
        boilerplate: false,
        elevation: 2,
      },
    };
  },
  watch: {
    date() {
      this.getHistory();
    }
  },
  methods: {
    getHistory() {
      const postData = {};
      postData.date = this.date;
      axios.post(`/quotations/getChaseHistory.json?token=${this.token}`, postData)
          .then((response) => {
            this.salespeople = response.data.salespeople;
            this.monthName = response.data.month;
            this.year = response.data.year;
          });
    },
    exportToExcel() {
      const postData = {};
      postData.date = this.date;
      axios.post(`/spreadsheets/chaseHistory.json?token=${this.token}`, postData, {responseType:'blob'})
          .then((response) => {
            const href = URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `chase_history_for_month_${this.monthName}-${this.year}.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
          });
    },
  },
  mounted() {
    this.getHistory();
  },
};
</script>
